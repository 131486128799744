import React, { useRef, useState } from 'react'
import { css } from '@emotion/react'
import Seo from '~components/Seo'
import Section from '~components/Section'
import styled from '@emotion/styled'
import { useFlexSearch } from 'react-use-flexsearch'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import DataViewer from '~utils/DataViewer'
import Tile from '~components/Tile'
import ProductionTile from '~components/ProductionTile'
import { LargeRarr } from '~components/Svg'
import { mobile, tablet } from '~styles/global'
import { useEffect } from 'react'

const SearchPage = ({ data }) => {

	const inputRef = useRef()
	const [term, setTerm] = useState(undefined)
	const [focus, setFocus] = useState(false)

	const { index, store } = data.localSearchPages

	const handleSubmit = e => {
		e.preventDefault()
		let term = e.target[0].value
		setTerm(term)
	}

	const results = useFlexSearch(term, index, store, {
		language: 'en',
		tokenize: 'full'
	})

	useEffect(() => {
		if(inputRef.current){
			inputRef.current.focus()
		}
	}, [inputRef.current])

	return (
		<>
			<Seo
				title="Search"
			/>
			<Section><Title>Search</Title></Section>
			<Section>
				<SearchBar onSubmit={handleSubmit}>
					<Input 
						type="text" 
						className='caption' 
						ref={inputRef} 
						autoFocus
						placeholder={'enter search term'} 
						onFocus={() => setFocus(true)}
					/>
					<Submit type='submit' opacity={focus && term}><LargeRarr /></Submit>
				</SearchBar>
			</Section>
			<Results>
				<ResultsTitle>Results</ResultsTitle>
				{ (term && results.length === 0) ? 
					<NoResults>
					No results to show
					</NoResults>
					:
					results.map(r => (
						r._type === 'graduate' ?
							<ResultTile g={r} key={r.title} color={r.course?.length && r?.course[0]?.keyColour}/>
							:
							<ProductionTile p={r} key={r.title}/>
					)) 
				}
			</Results>
			<DataViewer data={results} name='results' />	
		</>
	)
}

const Title = styled.h1`
	grid-column: span 12;
	margin-bottom: 94px;
`
const SearchBar = styled.form`
	grid-column: span 12;
	display: flex;
	margin-bottom: 76px;
`
const Input = styled.input`
	flex: 1;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid;
	color: var(--white);
	opacity: 0.5;
	padding: 10px 5px;
	font-family: inherit;
	::placeholder{
		color: var(--white);
		opacity: 0.5;
		font-family: inherit;
	}
	:focus {
		opacity: 1;
	}
	${tablet}{
		font-size: 16px;
		padding: 8px 5px;
	}
`
const Submit = styled.button`
	width: 69px;
	opacity: ${ props => props.opacity ? '1' : '0.5'};
	:hover{
		opacity: 1;
	}
	${tablet}{
		margin-left: 16px;
    width: 37px;
	}
`
const Results = styled(Section)`
	>div {
		grid-column-gap: 0;
	}
`
const ResultsTitle = styled.h1`
	grid-column: span 12;
	margin-bottom: 106px;
	${tablet}{
		margin-bottom: 24px;
	}
`
const ResultTile = styled(Tile)`
	grid-column: span 3;
`
const NoResults = styled.div`
	grid-column: span 12;
`
export const query = graphql`
	{
		localSearchPages {
			store
			index
		}
	}
`


SearchPage.propTypes = {
	data: PropTypes.node
}

export default SearchPage
